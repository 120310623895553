html,
body {
  background-color: black;
  font-size: 20px;
  font-family: sans-serif;
}

:root {
  --brightness: 1;
  --contrast: 100%;
}

#root {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  filter: brightness(var(--brightness)) contrast(var(--contrast));
}

.views-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#horizontal-views-container {
  flex-direction: column;
}
#vertical-views-container {
  flex-direction: row;
}

.view {
  /*border: 1px solid white;*/
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#view-top {
}
#view-right {
}
#view-bottom {
}
#view-left {
}

#center-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#center {
  border: 2px solid #3b3b3b;
  box-sizing: border-box;
  background-color: #000000;
}

.selector {
  position: absolute;
  top: 0;
  color: white;
  font-size: 1.2rem;
}